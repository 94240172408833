<div class="d-flex w-100 mt-2 pb-2 border-bottom border-dark">
  <a class="mr-2 align-self-center" (click)="onLogoClicked($event)">
    <img
      src="{{ assetPath }}/images/logo-bg2-standard.svg"
      alt="BankingGuide"
      class="bg-logo"
      width="200"
      height="50"
    />
  </a>
  <div class="d-flex flex-grow-1">
    <div class="d-flex flex-column">
      <nav
        mat-tab-nav-bar
        mat-stretch-tabs="false"
        mat-align-tabs="start"
        class="top-bar vr-text-regular main-nav"
        [tabPanel]="tabPanel"
        [style.width.px]="maxNavBarWidth"
      >
        @for (topic of menuConfig; track topic; let i = $index) {
          <a
            mat-tab-link
            mat-align-tabs="left"
            [active]="activeTopic === topic.name"
            (click)="onTopicClicked(topic.name)"
          >
            {{ topic.displayname }}
          </a>
        }
      </nav>
      <div #subNav class="sub-nav">
        @for (topic of menuConfig; track topic) {
          @if (activeTopic === topic.name && activeEntities) {
            <nav
              mat-tab-nav-bar
              mat-stretch-tabs="false"
              mat-align-tabs="start"
              [tabPanel]="tabPanelSub"
              [style.width.px]="maxNavBarWidth"
            >
              @for (entity of activeEntities; track entity; let first = $first; let last = $last) {
                <a
                  mat-tab-link
                  class="subtopic"
                  [disabled]="isLoading"
                  [active]="entity.active"
                  [ngClass]="{ 'first-subtopic': first, 'last-subtopic': last }"
                  (click)="onEntityClicked(entity)"
                >
                  {{ getPluralTitle(entity.name) }}
                </a>
              }
            </nav>
          }
        }
      </div>
    </div>
    <mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>
    <mat-tab-nav-panel #tabPanelSub></mat-tab-nav-panel>
  </div>

  <div class="d-flex align-items-center">
    <div class="d-flex" style="z-index: 99">
      @if (currentContext !== 'hub' && instances.length > 0) {
        <input hidden #customisationUpload type="file" (change)="uploadCustomization($event)" accept=".xlsx" />
        @if (wideEnoughForButtons) {
          <div class="mr-3 d-flex">
            @if (isTestButtonShown) {
              <vr-button label="Individualisierung" (click)="handleCustomization()" preset="small"></vr-button>
            }
          </div>
          @if (isTestButtonShown) {
            <div class="mr-3 d-flex">
              <vr-button label="Testberatungen" (click)="navigateToTest()" preset="small"></vr-button>
            </div>
          }

          @if (isGraphButtonShown) {
            <div class="mr-3 d-flex">
              <vr-button label="Grafische Ansicht" (click)="navigateToGraph()" preset="small"></vr-button>
            </div>
          }
        } @else {
          @if (instances.length > 0) {
            <vr-button
              class="mr-2"
              preset="small"
              label="Mehr"
              [icon]="buttonIcon.more"
              [noMinWidth]="true"
              [textColor]="color.find(color.name.Neutral700)"
              (click)="moreOptionsDialogCA($event)"
            >
            </vr-button>
          }
        }
      }
    </div>
    @if (currentContext === 'hub') {
      @if (wideEnoughForButtons) {
        <vr-button class="mr-3" preset="small" label="Inhalt zurücksetzen" (click)="onResetHub($event)"></vr-button>
        @if (isLicenseButtonShown) {
          <vr-button
            class="mr-3"
            preset="small"
            label="Lizenz aktualisieren"
            (click)="onUpdateLicense($event)"
          ></vr-button>
        }
      } @else {
        <vr-button
          class="mr-2"
          preset="small"
          label="Mehr"
          [icon]="buttonIcon.more"
          [noMinWidth]="true"
          [textColor]="color.find(color.name.Neutral700)"
          (click)="moreOptionsDialogHub($event)"
        >
        </vr-button>
      }
    }

    <div class="d-flex mr-3 align-items-center">
      @if (currentContext === 'hub') {
        <div class="mx-2 vr-text-small" [style.color]="color.find(color.name.Neutral700)">
          User Id: {{ currentTenantId }}
        </div>
      } @else {
        <mat-form-field class="mx-2">
          <mat-label> Instanz Id (User Id: {{ currentTenantId }}) </mat-label>
          <mat-select
            [placeholder]="'Instance Id'"
            [disabled]="instances.length === 0"
            [value]="instances.length !== 0 ? currentInstanceId : 'noId'"
            (selectionChange)="onInstanceChange($event)"
          >
            @if (instances.length === 0) {
              <mat-option [value]="'noId'">Keine Instanzen </mat-option>
            }

            @for (instance of instances; track instance) {
              <mat-option [value]="instance.id"> {{ instance.name }} ({{ instance.status }}) </mat-option>
            }
          </mat-select>
        </mat-form-field>
      }
    </div>
    <div class="d-flex align-items-center px-2">
      <nav
        mat-tab-nav-bar
        mat-stretch-tabs="false"
        mat-align-tabs="start"
        class="top-bar vr-text-regular mr-2 endpoint"
        [tabPanel]="tabPanelSwitchContext"
      >
        <nav
          mat-tab-link
          class="endpoint"
          [active]="activeEndpoint === contextEnum.configApp"
          [disabled]="isLoading || instances.length === 0"
          (click)="onSwitchContext(contextEnum.configApp)"
        >
          Instanzen
        </nav>
        <nav
          mat-tab-link
          class="endpoint"
          [active]="activeEndpoint === contextEnum.bankHub"
          [disabled]="isLoading"
          (click)="onSwitchContext(contextEnum.bankHub)"
        >
          Grundeinstellungen
        </nav>
      </nav>
      <sticky-button></sticky-button>

      @if (platform === 'aws') {
        <div class="d-flex">
          <vr-button
            preset="small"
            label="Log out"
            [noMinWidth]="true"
            [buttonColor]="color.find(color.name.Neutral200)"
            (click)="onLogoutClicked($event)"
          ></vr-button>
        </div>
      }

      <mat-tab-nav-panel #tabPanelSwitchContext></mat-tab-nav-panel>
    </div>
  </div>
</div>
